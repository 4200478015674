.navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 64px;
    width: 100%;
    padding: 0 30px;
    z-index: 100;
}

.float-score {
    display: flex;
    position: absolute;
    top: 0;
    right: 36px;
    font-size: 14px;
    font-style: italic;
}
.container {
    max-width: 100%;
    border-radius: 15px;
    padding: 1em;
    margin: 65px 0;
    background-color: #eef3f8;
}
// .container {
//     max-width: 80%;
//     border-radius: 15px;
//     padding: 1em;
//     margin: 65px 10% 0 10%;
//     background-color: white;
// }

.container.main-container {
    /* subtract the approximate height of the footer from the page height
    and the footer will always be at the bottom */
    min-height: calc(100vh - 150px);
}

.jumbotron,
.jumbotron > .container {
    background-color: #99defa;
}

// .footer {
//     text-align: center;
// }

.social-image {
    height: 25px;
    width: 25px;
    margin: 4px;
}

/* ngauge: link colors */
a,
td > a:not([href]),
.link {
    color: #00acf3;
    text-decoration: none;
}

a:hover,
a:focus,
.link:hover {
    color: #00a4e5;
    text-decoration: underline;
    cursor: pointer;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

th.sort {
    color: #00acf3;
    text-decoration: none;
}

th.sort:hover {
    color: #00a4e5;
    text-decoration: underline;
    cursor: pointer;
}

/* ngauge: btn-primary colors */
.btn-primary {
    color: #fff;
    background-color: #00acf3;
    border-color: #00a9ed;
    white-space: normal;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #00a4e5;
    border-color: #00a9ed;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #00acf3;
    border-color: #00a9ed;
}

.btn-primary .badge {
    color: #00acf3;
    background-color: #fff;
}

/* nGauge header HR */
.pageHeader {
    border-radius: 15px;
    margin-top: 4px;
    padding: 2px 15px 0 15px;
    background-color: #40c452;
    color: white;
    border: 4px solid #00acf3;
    font-size: 2em;
}

/* nGauge spacing */
.row {
    margin: 6px;
}

.fa {
    margin-right: 12px;
}

/* nGauge response prompt (eg. "Score") */
.responsePrompt {
    text-align: center;
    margin-top: 1em;
    border-bottom: 1px solid #40c452;
    width: 100%;
}

.examName {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 1em;
}

/* nGauge input prompt colors */
div.score-group {
    border: 2px solid #00a9ed;
    border-radius: 6px;
}

.input-group-sm > label {
    padding: 4px 2px;
}

.input-group-sm > div {
    padding: 0;
}

.input-group-sm > div > input {
    padding: 2px;
    height: 100%;
}

.input-group .primary {
    color: rgb(255, 255, 255);
    background-color: #00acf3;
    border-color: #00a9ed;
}

.input-group .success {
    color: rgb(255, 255, 255);
    background-color: #43cc55;
    border-color: #40c452;
}

.input-group .info {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 179, 215);
    border-color: rgb(38, 154, 188);
}

.input-group .warning {
    color: rgb(255, 255, 255);
    background-color: rgb(240, 173, 78);
    border-color: rgb(238, 162, 54);
}

.input-group .danger {
    color: rgb(255, 255, 255);
    background-color: #e57070;
    border-color: #e04a4a;
}

.input-group-btn .link {
    color: #000;
    background-color: #dfdfdf;
}

div.modal-dialog.modal-lg {
    max-width: 1200px;
}

.table > tbody > tr {
    border-bottom: 1px solid #dee2e6;
}

.table > thead > tr > th,
.table > tbody > tr > th {
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tbody > tr > td {
    padding: 7px;
    line-height: 1.2em;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
}

tr.table-step {
    color: white;
    background-color: #00acf3;
}

#progress-wrp {
    border: 1px solid #00a9ed;
    padding: 1px;
    position: relative;
    height: 30px;
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 15px;
    text-align: left;
    background: #fff;
    box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
}
#progress-wrp .progress-bar {
    height: 100%;
    border-radius: 4px;
    background-color: #00acf3;
    width: 0;
    box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}
#progress-wrp .status {
    top: 3px;
    left: 50%;
    position: absolute;
    display: inline-block;
    color: #000000;
}
.error-msg {
    color: red;
}
.loginWrapper {
    border-radius: 10px;
    max-width: 720px;
    padding: 15px 35px;
    margin: 80px auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

/* nGauge cards */
.card {
    border-color: #00a9ed;
    margin-bottom: 20px;
}

.card > .card-header {
    background-color: #00acf3;
}

.card > .card-header.pointer,
a.nav-link {
    cursor: pointer;
}

.card > .card-header > .card-title {
    margin: 0;
}

.text-right {
    text-align: right;
}

/* file uploads */
.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
}

.file-drop > .file-drop-target {
    outline: 2px dashed #73d1f8;
    outline-offset: -8px;
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 2px;

    color: #00acf3;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
    border: none;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: none;
    z-index: 50;
    opacity: 1;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    box-shadow: 0 0 13px 3px #4dc5f7;
}

.fa {
    cursor: pointer;
}

form > div > span {
    color: red;
}

form > div > div > span {
    color: red;
}
.clickable {
    cursor: pointer;
}

/* when nav-links are nested in dropdown items, they get double padding, which is not needed */
.dropdown-item > .nav-link {
    padding: 5px 0px;
}

.dropdown-menu.show > .dropdown-item {
    padding: 5px 10px;
}

td > .nav-link {
    padding: 0px;
}

/* this is necessary to get font awesome icons within another component to 
fire the events of the parent component.
see https://gomakethings.com/detecting-click-events-on-svgs-with-vanilla-js-event-delegation/*/
svg.svg-inline--fa {
    /*pointer-events: none;*/
}

.icon-primary {
    color: #00acf3;
}

.icon-danger {
    color: red;
}

.student-uploads-modal {
    height: 80vh;
    max-width: 1000px;
    .modal-content {
        height: 100%;
        .modal-body {
            padding: 0px !important;
            overflow: hidden;
        }
        .row {
            height: 100%;
        }
    }
    .file-selection-column {
        height: 100%;
        border: 1px solid #dee2e6;
        overflow-y: auto;
        .upload-item {
            border-bottom: 1px solid #dee2e6;
            display: flex;
            align-items: flex-start;
            padding: 5px;
            &:hover {
                background-color: #00acf3;
                color: white;
                cursor: pointer;
            }
        }
        .no-upload-item {
            border-bottom: 1px solid #dee2e6;
            display: flex;
            align-items: flex-start;
            padding: 5px;
        }
        .upload-item-type-header {
            border-bottom: 1px solid #dee2e6;
            background-color: #f0f0f0;
            text-align: center;
            font-size: 10px;
            font-weight: 600;
            padding: 3px;
        }
    }
    .file-display-column {
        max-height: 100%;
        overflow-y: hidden;
        .no-image {
            width: 100%;
            text-align: center;
            margin-top: 20%;
        }
    }
}
