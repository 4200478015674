div.expand-dot {
    height: 24px;
    width: 24px;
    border: 2px solid #00acf3;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
}

div.expand-dot > svg {
    fill: #00acf3;
    position: relative;
    top: -4px;
}
