.recategorize {
    background-color: rgba(240, 173, 78, 0.5);
    border: 2px solid #e04a4a;
    border-radius: 8px;
    margin: 0;
    max-width: 100%;
    margin-bottom: 20px;
}

.recategorize > .row > .col {
    text-align: center;
}

select.dropdown {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin: 0 3px;
}

.scoringRubric {
    border: 2px solid;
    border-radius: 8px;
    position: sticky;
    top: 64px;
    margin: 0;
    max-width: 100%;
    margin-bottom: 20px;
}

.scoringComments {
    border: 2px solid;
    border-radius: 8px;
    margin: 0;
    max-width: 100%;
    margin-bottom: 20px;
}
