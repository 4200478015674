div.border-primary {
    border-color: #00a4e5 !important;
}
div.toolbar {
    position: relative;
    background-color: #26b8f5;
    padding: 5px 20px;
    margin: auto;
    text-align: right;
}
div.toolbar button {
    border: 1px solid darkgrey;
    border-radius: 3px;
    padding: 2px;
    margin-right: 4px;
    background-color: darkgrey;
}
