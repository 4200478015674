.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.waves-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    margin-top: 2rem;
    pointer-events: none;
    z-index: -1;
}

.wave {
    width: 100%;
    height: auto;
}

.wave-yellow {
    position: absolute;
    bottom: 0;
    z-index: -2;
    width: 100%;
}

.wave-blue {
    position: absolute;
    bottom: 0;
    z-index: -3;
    width: 100%;
}

.footer {
    width: 100%;
    padding-bottom: 2rem;
    overflow: hidden; /* Ensure that any overflow is hidden */
}
