/* image-container is the div outside the image-flex/image-modal div */
div.image-container {
    align-items: center;
};

/* image-modal is the class for a zoomed-in image */
div.image-modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.5);
}

div.image-modal > img {
    display: block;
    position: absolute;
    top: 1vw;
    left: 1vh;
}

/* image-flex is the class for a zoomed-out image */
div.image-flex {
    height: calc(100% - 25px);
}

div.image-flex > img {
    max-height: 100%;
    max-width: 100%;
}

img.response-image {
    border: 2px solid #00a9ed;
    border-radius: 5px;
    cursor: pointer;
    flex: 0 0 0;
}
