.responseText {
    border: 2px solid #00a9ed;
    border-radius: 5px;
    background-color: #bfeafc;
    margin-bottom: 2px;
}

div.public-DraftEditor-content {
    min-height: 6em;
}
