.countdown-timer {
    padding: 0.3rem;
    border: 2px solid #00acf3;
    border-radius: 4px;
    background: #bfeafc;

    z-index: 900;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 14rem;
    max-width: 50%;
    text-align: center;
}

.countdown-timer.warning {
    border: 2px solid #ff0000;
}

.countdown-timer > p {
    margin: 0;
}

.countdown-timer > span {
    font-size: 1.5rem;
    font-weight: 600;
}

.countdown-timer.warning > span {
    color: #ff0000;
}
