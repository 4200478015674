.root.pdf-popup-page {
    margin-top: 40px;
    text-align: center;
}

.pdf-controls-container {
    .col {
        padding-bottom: 10px;
    }
    div.zoom-controls,
    div.page-controls {
        display: flex;
        justify-content: space-around;
        span {
            font-weight: 600;
            display: inline-flex;
            align-items: center;
        }
    }
}

.spinner-border {
    margin-top: 150px;
}
