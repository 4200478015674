html,
body {
    margin: 0;
    padding: 0;
}

.template-container {
    width: 100%;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.header {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.logo {
    width: 80px;
    height: 80px;
    background-color: #1f6bb6;
    border-radius: 50%;
    padding: 10px;
    border: 5px solid #ffc609;
    margin-top: 10px;
}

.header-title {
    color: #1f6bb6;
    font-size: 2.5rem;
    margin-top: 1rem;
    text-align: center;
}

.content {
    text-align: center;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.content h5 {
    color: #1f6bb6;
    font-size: 1.2rem;
}

/* input {
    max-width: 20%;
    margin: 0 auto;
  } */

.btn-primary {
    /* width: 10%; */
    background-color: #1f6bb6;
}

label {
    color: #1f6bb6;
    text-decoration: underline;
}

.message-box {
    background-color: #f5f5f5;
    padding: 1rem;
    border: 2px solid #ffc609;
    border-radius: 10px;
    margin-top: 2rem;
}

.message-box p {
    color: #1f6bb6;
    font-size: 1rem;
    margin: 0;
}

.message-box .note {
    margin-top: 1rem;
    font-size: 0.9rem;
}
