.home-container {
    padding: 2rem;
    background-color: #eef3f8;
    text-align: center;
    font-family: 'Trebuchet MS', sans-serif;
}
.home-container .container {
    background-color: #eef3f8;
    align-items: center;
}
.home-container h1 {
    margin-bottom: 2rem;
    font-weight: bold;
    color: #1f6bb6;
}

.header-icon {
    width: 13%;
    margin-bottom: 2.5rem;
}

.home-options {
    margin-bottom: 1rem;
}

.home-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem; /* Increased padding for more space */
    margin: 1rem 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #1f6bb6;
    font-weight: bold;
    font-size: 25px;
}

.home-option .icon {
    width: 25%;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 1rem;
}

.home-option input {
    margin-bottom: 1rem; /* Space between input and button */
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.home-option button {
    padding: 0.5rem;
    background-color: #1f6bb6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.home-option button:hover {
    background-color: #ffc609;
}

.contact-email {
    margin-top: 2rem;
    color: #ffffff;
    background-color: #1f6bb6;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;
}
a:hover {
    color: #ffc609;
    text-decoration: none;
}
.contact-email a {
    color: #ffffff;
    text-decoration: none;
}
.contact-email a:hover {
    color: #ffc609;
    text-decoration: none;
}
