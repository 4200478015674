.pdf-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}

@media print {
    .pdf-container {
        display: none;
    }
}
