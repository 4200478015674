.lContainer {
    display: inline-block;
    position: relative;
    height: 36px;
    width: 180px;
}

.lContainer.lImage {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.2);
}

.lDefault {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 36px;
}
.lDefault span {
    position: absolute;
    font-size: 24px;
    top: 1px;
    left: 40px;
}
.lDefault div {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #00acf3;
    border-radius: 50%;
    animation: lDefault 1.2s linear infinite;
}
.lDefault div:nth-child(1) {
    animation-delay: 0s;
    top: 16px;
    left: 30px;
}
.lDefault div:nth-child(2) {
    animation-delay: -0.1s;
    top: 10px;
    left: 28px;
}
.lDefault div:nth-child(3) {
    animation-delay: -0.2s;
    top: 5px;
    left: 23px;
}
.lDefault div:nth-child(4) {
    animation-delay: -0.3s;
    top: 3px;
    left: 16px;
}
.lDefault div:nth-child(5) {
    animation-delay: -0.4s;
    top: 5px;
    left: 10px;
}
.lDefault div:nth-child(6) {
    animation-delay: -0.5s;
    top: 10px;
    left: 5px;
}
.lDefault div:nth-child(7) {
    animation-delay: -0.6s;
    top: 16px;
    left: 3px;
}
.lDefault div:nth-child(8) {
    animation-delay: -0.7s;
    top: 23px;
    left: 5px;
}
.lDefault div:nth-child(9) {
    animation-delay: -0.8s;
    top: 28px;
    left: 10px;
}
.lDefault div:nth-child(10) {
    animation-delay: -0.9s;
    top: 30px;
    left: 16px;
}
.lDefault div:nth-child(11) {
    animation-delay: -1s;
    top: 28px;
    left: 23px;
}
.lDefault div:nth-child(12) {
    animation-delay: -1.1s;
    top: 23px;
    left: 28px;
}
@keyframes lDefault {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
